import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="bg-gray-800 text-white py-6 sm:py-8">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex flex-col md:flex-row justify-between items-center">
          <div className="mb-4 md:mb-0 text-center md:text-left">
            <img src="/logo.png" alt="PaletteHunt Logo" className="h-8 w-auto mx-auto md:mx-0" />
            <p className="mt-2 text-sm sm:text-base">© 2023 PaletteHunt. All rights reserved.</p>
          </div>
          <div className="flex flex-wrap justify-center md:justify-end space-x-4 sm:space-x-6">
            <Link to="/" className="hover:text-primary transition duration-300 text-sm sm:text-base">Home</Link>
            <Link to="/about" className="hover:text-primary transition duration-300 text-sm sm:text-base">About</Link>
            <Link to="/contact" className="hover:text-primary transition duration-300 text-sm sm:text-base">Contact</Link>
            <Link to="/privacy-policy" className="hover:text-primary transition duration-300 text-sm sm:text-base">Privacy Policy</Link>
            <Link to="/terms-and-conditions" className="hover:text-primary transition duration-300 text-sm sm:text-base">Terms & Conditions</Link>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;