import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { Analytics } from '@vercel/analytics/react';
import { SpeedInsights } from '@vercel/speed-insights/react';
import LandingPage from './components/LandingPage';
import Login from './components/Login';
import PaymentScreen from './components/PaymentScreen';
import ImageUpload from './components/ImageUpload';
import WaitingScreen from './components/WaitingScreen';
import ColorAnalysisTest from './components/ColorAnalysisTest';
import Playground from './components/Playground';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsAndConditions from './components/TermsAndConditions';
import Credits from './components/Credits';

function App() {
  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <Router>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/login" element={<Login />} />
          <Route path="/payment" element={<PaymentScreen />} />
          <Route path="/upload" element={<ImageUpload />} />
          <Route path="/waiting" element={<WaitingScreen />} />
          <Route path="/color-analysis" element={<ColorAnalysisTest />} />
          <Route path="/credits" element={<Credits />} />
          <Route path="/playground" element={<Playground />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        </Routes>
      </Router>
      <Analytics />
      <SpeedInsights />
    </GoogleOAuthProvider>
  );
}

export default App;