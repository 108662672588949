import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { motion } from "framer-motion";
import axios from 'axios';
import { ClipLoader } from 'react-spinners';
import { FaDownload, FaPalette } from 'react-icons/fa';

const ColorBox = ({ color, name }) => (
  <div className="flex flex-col items-center">
    <div
      className="w-20 h-20 rounded-lg shadow-lg transform transition-transform duration-300 hover:scale-110"
      style={{ backgroundColor: color }}
    ></div>
    <span className="mt-2 text-sm font-medium">{name}</span>
  </div>
);

const ImageWithDownload = ({ src, alt }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = src;
    link.download = alt.replace(/\s+/g, '_').toLowerCase() + '.jpg';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div 
      className="relative"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <img
        src={src}
        alt={alt}
        className="w-full h-auto rounded-lg shadow-lg"
      />
      {isHovered && (
        <button
          onClick={handleDownload}
          className="absolute top-2 right-2 bg-primary text-white p-2 rounded-full hover:bg-secondary transition-colors duration-200"
        >
          <FaDownload />
        </button>
      )}
    </div>
  );
};

const ColorSection = ({ title, description, colors, pics, isReversed, isMobile }) => {
  const gradientStyle = {
    backgroundImage: `linear-gradient(to right, ${colors[0]?.color_code}, ${colors[2]?.color_code})`,
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  };

  const content = (
    <>
      <motion.h3
        initial={{ opacity: 0, x: -50 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.6, delay: 0.2 }}
        className="text-5xl font-bold mb-4"
        style={gradientStyle}
      >
        {title}
      </motion.h3>
      <motion.div
        initial={{ opacity: 0, x: -50 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.6, delay: 0.4 }}
        className="text-gray-300 text-lg leading-relaxed mb-8"
      >
        {description.split('. ').map((sentence, index) => (
          <p key={index} className="mb-2">{sentence}.</p>
        ))}
      </motion.div>
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6, delay: 0.6 }}
        className="flex justify-around mb-8"
      >
        {Array.isArray(colors) && colors.map((color, index) => (
          <ColorBox key={index} color={color.color_code} name={color.color_name} />
        ))}
      </motion.div>
    </>
  );

  const images = (
    <motion.div
      initial={{ opacity: 0, x: 50 }}
      animate={{ opacity: 1, x: 0 }}
      transition={{ duration: 0.6, delay: 0.4 }}
      className="grid grid-cols-1 gap-4"
    >
      {pics && Object.values(pics).map((pic, index) => (
        <ImageWithDownload
          key={index}
          src={pic}
          alt={`${title} example ${index + 1}`}
        />
      ))}
    </motion.div>
  );

  if (isMobile) {
    return (
      <motion.div
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
        className="mb-16"
      >
        {content}
        {images}
      </motion.div>
    );
  }

  return (
    <motion.div
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.8 }}
      className={`flex flex-col lg:flex-row items-center mb-32 ${
        isReversed ? "lg:flex-row-reverse" : ""
      }`}
    >
      <div className="lg:w-1/2 mb-8 lg:mb-0 px-8">
        {content}
      </div>
      <div className="lg:w-1/2 mb-8 lg:mb-0 px-8">
        {images}
      </div>
    </motion.div>
  );
};

const JewelrySection = ({ jewelColor, explanation, pics, isMobile }) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.8 }}
      className="mt-16 text-center"
    >
      <h3 className="text-4xl font-bold mb-4 bg-gradient-to-r from-yellow-400 to-yellow-200 bg-clip-text text-transparent">
        {jewelColor === 'gold' ? 'Gold Jewelry' : 'Silver Jewelry'}
      </h3>
      <p className="text-gray-300 text-lg leading-relaxed mb-8 max-w-3xl mx-auto">
        {explanation}
      </p>
      <div className={`grid grid-cols-1 ${isMobile ? '' : 'md:grid-cols-2'} gap-4 max-w-3xl mx-auto`}>
        {pics && Object.values(pics).map((pic, index) => (
          <ImageWithDownload
            key={index}
            src={pic}
            alt={`${jewelColor} jewelry example ${index + 1}`}
          />
        ))}
      </div>
    </motion.div>
  );
};

const Separator = () => (
  <div className="w-full flex justify-center my-16">
    <div className="w-1/3 h-px bg-gradient-to-r from-transparent via-gray-400 to-transparent"></div>
  </div>
);

const ColorAnalysisTest = () => {
  const [scrollY, setScrollY] = useState(0);
  const [playbook, setPlaybook] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { googleId, uuid } = useParams();
  const [generationStatus, setGenerationStatus] = useState('idle');

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  useEffect(() => {
    const handleScroll = () => setScrollY(window.scrollY);
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    const verifyTokenAndFetchPlaybook = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        navigate('/login');
        return;
      }

      try {
        // First, verify the token
        const verifyResponse = await axios.post(
          `${process.env.REACT_APP_API_GATEWAY_ENDPOINT}/verify-token`,
          { token },
          {
            headers: {
              'Content-Type': 'application/json'
            }
          }
        );

        if (!verifyResponse.data.valid) {
          localStorage.removeItem('token');
          navigate('/login');
          return;
        }

        const uuid = verifyResponse.data.uuid;

        // If token is valid, fetch the playbook
        const playbookResponse = await axios.get(
          `${process.env.REACT_APP_API_GATEWAY_ENDPOINT}/create-playbook`,
          {
            params: { token, uuid }
          }
        );

        if (playbookResponse.data.message === 'Playbook generation started') {
          setGenerationStatus('generating');
          pollPlaybookStatus(uuid);
        } else if (playbookResponse.data.playbook) {
          setPlaybook(playbookResponse.data.playbook);
          setGenerationStatus('completed');
        } else {
          setError('Playbook not found');
        }
      } catch (error) {
        console.error('Error:', error);
        if (error.response && error.response.status === 401) {
          localStorage.removeItem('token');
          navigate('/login');
        } else {
          setError('An error occurred. Please try again.');
        }
      } finally {
        setLoading(false);
      }
    };

    verifyTokenAndFetchPlaybook();
  }, [navigate, googleId, uuid]);

  const pollPlaybookStatus = async (uuid) => {
    const pollInterval = setInterval(async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_GATEWAY_ENDPOINT}/create-playbook`,
          {
            params: { token: localStorage.getItem('token'), uuid }
          }
        );

        if (response.data.playbook) {
          setPlaybook(response.data.playbook);
          setGenerationStatus('completed');
          clearInterval(pollInterval);
        }
      } catch (error) {
        console.error('Error polling playbook status:', error);
      }
    }, 10000); // Poll every 10 seconds

    // Clear interval after 10 minutes (60 * 10 * 1000 ms)
    setTimeout(() => {
      clearInterval(pollInterval);
      if (generationStatus !== 'completed') {
        setError('Playbook generation timed out. Please try again later.');
      }
    }, 600000);
  };

  const pageVariants = {
    initial: { opacity: 0, y: 20 },
    in: { opacity: 1, y: 0 },
    out: { opacity: 0, y: -20 }
  };

  const pageTransition = {
    type: "tween",
    ease: "anticipate",
    duration: 0.5
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gradient-to-br from-gray-900 to-gray-800">
        <ClipLoader color="#ffffff" loading={loading} size={50} />
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gradient-to-br from-gray-900 to-gray-800 text-white">
        <h1 className="text-3xl font-bold">{error}</h1>
      </div>
    );
  }

  if (generationStatus === 'generating') {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gradient-to-br from-gray-900 to-gray-800 text-white">
        <h1 className="text-3xl font-bold">Generating your color analysis... This may take a few minutes.</h1>
      </div>
    );
  }

  if (!playbook || !playbook.style_analysis) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gradient-to-br from-gray-900 to-gray-800 text-white">
        <h1 className="text-3xl font-bold">Error: Playbook data is missing or invalid.</h1>
      </div>
    );
  }

  return (
    <motion.div
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
      className="bg-gray-900 text-white min-h-screen"
    >
      <div>
        <header
          className="w-full p-6 bg-gray-900 bg-opacity-90 backdrop-filter backdrop-blur-lg"
          style={{
            boxShadow: `0 4px 6px -1px rgba(0, 0, 0, ${Math.min(
              scrollY / 1000,
              0.1
            )})`,
          }}
        >
          <div className="max-w-7xl mx-auto flex justify-between items-center">
            <Link to="/" className="flex items-center">
              <img
                src="/logo.png"
                alt="PaletteHunt Logo"
                className="h-16 w-auto mr-4"
              />
            </Link>
            <Link
              to="/playground"
              className="bg-primary text-white font-semibold py-2 px-4 rounded-full hover:bg-opacity-90 transition duration-300 ease-in-out transform hover:scale-105 shadow-lg flex items-center"
            >
              <FaPalette className="mr-2" /> Color Playground
            </Link>
          </div>
        </header>
        <main className="p-8">
          <div className="max-w-7xl mx-auto">
            <motion.h1
              initial={{ opacity: 0, y: -50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
              className="text-6xl font-bold mb-8 text-center bg-gradient-to-r from-primary to-secondary text-transparent bg-clip-text"
            >
              Your Color Analysis Results
            </motion.h1>
            <motion.p
              initial={{ opacity: 0, y: -30 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, delay: 0.2 }}
              className="text-xl text-gray-300 text-center mb-16 max-w-3xl mx-auto"
            >
              Based on the photos you provided, our AI has analyzed your unique features and determined the color palette that best complements your natural coloring. Explore the sections below to discover the hues that will enhance your appearance and boost your confidence.
            </motion.p>

            {/* Add the introduction section */}
            {playbook.introduction && (
              <>
                <motion.div
                  initial={{ opacity: 0, y: -30 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.8, delay: 0.4 }}
                  className="text-xl text-gray-300 text-center mb-16 max-w-3xl mx-auto"
                >
                  <p className="mb-4">{playbook.introduction}</p>
                </motion.div>
                <Separator />
              </>
            )}

            <div className={isMobile ? "" : "space-y-32 mt-12"}>
              {Array.isArray(playbook.style_analysis.color_analysis) && playbook.style_analysis.color_analysis.map((section, index) => (
                <React.Fragment key={index}>
                  <ColorSection
                    title={section.shades}
                    description={section.explanation}
                    colors={section.colors_and_codes}
                    pics={section.pics}
                    isReversed={index % 2 !== 0}
                    isMobile={isMobile}
                  />
                  {index < playbook.style_analysis.color_analysis.length - 1 && <Separator />}
                </React.Fragment>
              ))}
            </div>

            {playbook.style_analysis.silver_or_gold && (
              <>
                <Separator />
                <motion.h2
                  initial={{ opacity: 0, y: -50 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.8 }}
                  className="text-5xl font-bold mb-6 mt-32 text-center"
                  style={{
                    background: 'linear-gradient(to right, #C0C0C0, #FFD700)',
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                    backgroundClip: 'text',
                    textFillColor: 'transparent'
                  }}
                >
                  Silver or Gold?
                </motion.h2>
                <JewelrySection
                  jewelColor={playbook.style_analysis.silver_or_gold.jewel_color}
                  explanation={playbook.style_analysis.silver_or_gold.explanation}
                  pics={playbook.style_analysis.silver_or_gold.pics}
                  isMobile={isMobile}
                />
              </>
            )}
            <div className="text-center mt-32">
              <motion.div
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8 }}
              >
                <p className="text-2xl font-semibold text-secondary mb-6">
                  Ready to explore your color palette further?
                </p>
                <Link
                  to="/playground"
                  className="bg-gradient-to-r from-primary to-secondary text-white font-semibold py-5 px-10 rounded-full hover:opacity-90 transition-all duration-300 ease-in-out transform hover:scale-105 hover:shadow-2xl shadow-lg text-xl inline-block"
                >
                  Go to Color Playground
                </Link>
              </motion.div>
            </div>
          </div>
        </main>
      </div>
      <footer className="bg-gray-800 text-white py-8 mt-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex flex-col md:flex-row justify-between items-center">
            <div className="flex items-center mb-4 md:mb-0">
              <img
                src="/logo.png"
                alt="PaletteHunt Logo"
                className="h-12 w-auto mr-4"
              />
              <span className="text-2xl font-bold">PaletteHunt</span>
            </div>
            <div className="flex space-x-6">
              <Link to="/about" className="hover:text-primary transition-colors">
                About
              </Link>
              <Link to="/contact" className="hover:text-primary transition-colors">
                Contact
              </Link>
              <Link to="/privacy" className="hover:text-primary transition-colors">
                Privacy Policy
              </Link>
            </div>
          </div>
          <div className="mt-8 text-center text-gray-400">
            © {new Date().getFullYear()} PaletteHunt. All rights reserved.
          </div>
        </div>
      </footer>
    </motion.div>
  );
};

export default ColorAnalysisTest;