import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import { CheckCircle2 } from 'lucide-react';
import { ClipLoader } from 'react-spinners';

const PaymentScreen = () => {
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isProcessing, setIsProcessing] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const verifyToken = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        navigate('/login');
        return;
      }

      try {
        const response = await axios.post(`${process.env.REACT_APP_API_GATEWAY_ENDPOINT}/verify-token`, { token });
        if (!response.data.valid) {
          navigate('/login');
        } else {
          setIsLoading(false);
        }
      } catch (error) {
        console.error('Token verification failed:', error);
        navigate('/login');
      }
    };

    verifyToken();
  }, [navigate]);

  const plans = [
    { 
      id: 'basic', 
      name: 'Basic Plan', 
      price: '$15',
      features: [
        'Color analysis playbook',
        '5 AI-generated images',
        'Basic color recommendations'
      ]
    },
    { 
      id: 'pro', 
      name: 'Pro Plan', 
      price: '$25',
      features: [
        'Everything in Basic',
        '15 AI-generated images',
        'Advanced color recommendations',
        'Seasonal color analysis'
      ]
    },
    { 
      id: 'enterprise', 
      name: 'Enterprise Plan', 
      price: '$42',
      features: [
        'Everything in Pro',
        'Unlimited AI-generated images',
        'Personalized styling consultation',
        'Priority support'
      ]
    }
  ];

  const handlePlanSelection = async (planId) => {
    setSelectedPlan(planId);
    setIsProcessing(true);

    const googleCredential = localStorage.getItem('googleCredential');
    if (!googleCredential) {
      navigate('/login');
      return;
    }

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_GATEWAY_ENDPOINT}/payment-checkout`, {
        credential: googleCredential,
        plan: planId
      });

      if (response.data.checkoutUrl) {
        window.location.href = response.data.checkoutUrl;
      } else {
        throw new Error('Unexpected response from server');
      }
    } catch (error) {
      console.error('Payment error:', error);
      // Handle error (show error message to user)
    } finally {
      setIsProcessing(false);
    }
  };

  if (isLoading) {
    return (
      <div className="bg-gradient-to-br from-gray-900 to-gray-800 text-white min-h-screen flex items-center justify-center">
        <ClipLoader color="#ffffff" loading={isLoading} size={50} />
      </div>
    );
  }

  return (
    <div className="bg-gradient-to-br from-gray-900 to-gray-800 text-white min-h-screen flex flex-col items-center justify-center p-4">
      <Link to="/" className="mb-8">
        <img src="/logo.png" alt="PaletteHunt Logo" className="h-16 w-auto" />
      </Link>
      
      <h1 className="text-4xl sm:text-5xl font-bold mb-4 text-center bg-gradient-to-r from-primary to-secondary text-transparent bg-clip-text">
        Unlock Your Perfect Palette
      </h1>
      
      <p className="text-xl text-gray-300 mb-8 text-center max-w-2xl">
        Choose the plan that best fits your style journey. Discover your unique colors and transform your wardrobe with AI-powered insights.
      </p>
      
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-12 w-full max-w-6xl">
        {plans.map((plan) => (
          <div 
            key={plan.id}
            className={`bg-gray-800 p-8 rounded-xl shadow-lg cursor-pointer transition-all transform hover:scale-105 ${selectedPlan === plan.id ? 'border-2 border-primary ring-4 ring-primary ring-opacity-50' : ''}`}
            onClick={() => handlePlanSelection(plan.id)}
          >
            <h2 className="text-2xl font-bold mb-4 text-primary">{plan.name}</h2>
            <p className="text-4xl font-bold mb-6">{plan.price}</p>
            <ul className="space-y-3 mb-8">
              {plan.features.map((feature, index) => (
                <li key={index} className="flex items-center text-gray-300">
                  <CheckCircle2 className="text-green-500 mr-2" size={20} />
                  <span>{feature}</span>
                </li>
              ))}
            </ul>
            <button 
              className={`w-full py-2 px-4 rounded-full font-semibold transition-colors ${selectedPlan === plan.id ? 'bg-primary text-gray-900' : 'bg-gray-700 text-white hover:bg-gray-600'}`}
              onClick={() => handlePlanSelection(plan.id)}
              disabled={isProcessing}
            >
              {isProcessing && selectedPlan === plan.id ? (
                <ClipLoader color="#ffffff" loading={true} size={20} />
              ) : (
                selectedPlan === plan.id ? 'Processing...' : 'Select Plan'
              )}
            </button>
          </div>
        ))}
      </div>
      
      <p className="mt-8 text-gray-400 text-center max-w-2xl">
        By proceeding, you agree to our <Link to="/terms" className="text-primary hover:underline">Terms of Service</Link> and <Link to="/privacy" className="text-primary hover:underline">Privacy Policy</Link>.
      </p>
    </div>
  );
};

export default PaymentScreen;