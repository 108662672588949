import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { GoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import { ClipLoader } from 'react-spinners';

const Login = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const handleGoogleSuccess = async (credentialResponse) => {
    setIsLoading(true);
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_GATEWAY_ENDPOINT}/verify-token`, 
        { idToken: credentialResponse.credential },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      
      if (response.data.valid) {
        localStorage.setItem('token', response.data.token);
        if (response.data.hasPaid) {
          navigate(response.data.nextPage);
        } else {
          localStorage.setItem('googleCredential', credentialResponse.credential);
          navigate('/payment');
        }
      } else {
        throw new Error('Invalid token');
      }
    } catch (error) {
      console.error('Login error:', error);
      // Handle error (show error message to user)
    } finally {
      setIsLoading(false);
    }
  };

  const handleGoogleError = (error) => {
    console.error('Google Login Failed:', error);
    // Handle login failure (show error message to user)
  };

  return (
    <div className="bg-gradient-to-br from-gray-900 to-gray-800 text-white min-h-screen flex flex-col items-center justify-center p-4">
      <div className="max-w-md w-full bg-gray-800 rounded-2xl shadow-2xl overflow-hidden">
        <div className="p-8">
          <Link to="/" className="flex items-center justify-center mb-8">
            <img src="/logo.png" alt="PaletteHunt Logo" className="h-16 w-auto mr-2" />
          </Link>
          
          <h2 className="text-4xl font-bold mb-2 text-center bg-gradient-to-r from-primary to-secondary text-transparent bg-clip-text">Welcome Back</h2>
          <p className="text-gray-400 text-center mb-8">Discover your perfect color palette</p>
          
          <div className="mb-4 flex flex-col items-center">
            {isLoading ? (
              <ClipLoader color="#ffffff" loading={isLoading} size={50} />
            ) : (
              <GoogleLogin
                clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                onSuccess={handleGoogleSuccess}
                onError={handleGoogleError}
                useOneTap={false}
                cookiePolicy={'single_host_origin'}
                size="large"
                text="signin_with"
                shape="pill"
                theme="filled_blue"
                width="300px"
              />
            )}
            <p className="text-[10px] text-gray-400 mt-1 text-center">
              By logging in, you agree to our{' '}
              <Link to="/terms" className="text-primary hover:underline">Terms of Service</Link>{' '}
              and{' '}
              <Link to="/privacy" className="text-primary hover:underline">Privacy Policy</Link>.
            </p>
          </div>
          
          <div className="space-y-4 bg-gray-700 p-6 rounded-xl mt-8">
            <h3 className="text-xl font-semibold mb-4 text-center">Why Choose PaletteHunt?</h3>
            {[
              "AI-powered color analysis",
              "Personalized style recommendations",
              "Interactive color playground",
              "Secure and private"
            ].map((feature, index) => (
              <div key={index} className="flex items-center">
                <svg className="w-5 h-5 mr-3 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                </svg>
                <span className="text-gray-300">{feature}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;