import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import { CheckCircle2 } from 'lucide-react';
import { ClipLoader } from 'react-spinners';

const Credits = () => {
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState(null); // Add this line
  const navigate = useNavigate();

  useEffect(() => {
    const verifyToken = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        navigate('/login');
        return;
      }

      try {
        const response = await axios.post(`${process.env.REACT_APP_API_GATEWAY_ENDPOINT}/verify-token`, { token });
        if (!response.data.valid) {
          navigate('/login');
        } else {
          setIsLoading(false);
        }
      } catch (error) {
        console.error('Token verification failed:', error);
        navigate('/login');
      }
    };

    verifyToken();
  }, [navigate]);

  const creditPlans = [
    { 
      id: 'basic_credits', 
      name: 'Basic Credits', 
      price: '$10',
      credits: 100,
      features: [
        '100 credits',
        'Generate up to 10 images',
        'Valid for 30 days'
      ]
    },
    { 
      id: 'pro_credits', 
      name: 'Pro Credits', 
      price: '$25',
      credits: 300,
      features: [
        '300 credits',
        'Generate up to 30 images',
        'Valid for 60 days',
        '10% bonus credits'
      ]
    },
    { 
      id: 'premium_credits', 
      name: 'Premium Credits', 
      price: '$50',
      credits: 700,
      features: [
        '700 credits',
        'Generate up to 70 images',
        'Valid for 90 days',
        '20% bonus credits',
        'Priority processing'
      ]
    }
  ];

  const handlePlanSelection = async (planId) => {
    setSelectedPlan(planId);
    setIsProcessing(true);
    setError(null); // Reset error state

    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/login');
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_GATEWAY_ENDPOINT}/credits-checkout`,
        { token, plan: planId },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          withCredentials: true // This is important for CORS with credentials
        }
      );

      console.log('Checkout response:', response);

      if (response.data.checkoutUrl) {
        window.location.href = response.data.checkoutUrl;
      } else {
        throw new Error('Unexpected response from server');
      }
    } catch (error) {
      console.error('Credits checkout error:', error);
      setError('Failed to initiate checkout. Please try again.');
    } finally {
      setIsProcessing(false);
    }
  };

  if (isLoading) {
    return (
      <div className="bg-gradient-to-br from-gray-900 to-gray-800 text-white min-h-screen flex items-center justify-center">
        <ClipLoader color="#ffffff" loading={isLoading} size={50} />
      </div>
    );
  }

  return (
    <div className="bg-gradient-to-br from-gray-900 to-gray-800 text-white min-h-screen flex flex-col items-center justify-center p-4">
      <Link to="/" className="mb-8">
        <img src="/logo.png" alt="PaletteHunt Logo" className="h-16 w-auto" />
      </Link>
      
      <h1 className="text-4xl sm:text-5xl font-bold mb-4 text-center bg-gradient-to-r from-primary to-secondary text-transparent bg-clip-text">
        Recharge Your Creativity
      </h1>
      
      <p className="text-xl text-gray-300 mb-8 text-center max-w-2xl">
        Choose a credit package to continue generating stunning AI-powered images and exploring your perfect color palette.
      </p>
      
      {error && <p className="text-red-500 mb-4">{error}</p>} {/* Add this line to display errors */}
      
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-12 w-full max-w-6xl">
        {creditPlans.map((plan) => (
          <div 
            key={plan.id}
            className={`bg-gray-800 p-8 rounded-xl shadow-lg cursor-pointer transition-all transform hover:scale-105 ${selectedPlan === plan.id ? 'border-2 border-primary ring-4 ring-primary ring-opacity-50' : ''}`}
            onClick={() => handlePlanSelection(plan.id)}
          >
            <h2 className="text-2xl font-bold mb-4 text-primary">{plan.name}</h2>
            <p className="text-4xl font-bold mb-6">{plan.price}</p>
            <p className="text-2xl font-semibold mb-6 text-secondary">{plan.credits} Credits</p>
            <ul className="space-y-3 mb-8">
              {plan.features.map((feature, index) => (
                <li key={index} className="flex items-center text-gray-300">
                  <CheckCircle2 className="text-green-500 mr-2" size={20} />
                  <span>{feature}</span>
                </li>
              ))}
            </ul>
            <button 
              className={`w-full py-2 px-4 rounded-full font-semibold transition-colors ${selectedPlan === plan.id ? 'bg-primary text-gray-900' : 'bg-gray-700 text-white hover:bg-gray-600'}`}
              onClick={() => handlePlanSelection(plan.id)}
              disabled={isProcessing}
            >
              {isProcessing && selectedPlan === plan.id ? (
                <ClipLoader color="#ffffff" loading={true} size={20} />
              ) : (
                selectedPlan === plan.id ? 'Processing...' : 'Select Plan'
              )}
            </button>
          </div>
        ))}
      </div>
      
      <p className="mt-8 text-gray-400 text-center max-w-2xl">
        By proceeding, you agree to our <Link to="/terms" className="text-primary hover:underline">Terms of Service</Link> and <Link to="/privacy" className="text-primary hover:underline">Privacy Policy</Link>.
      </p>
    </div>
  );
};

export default Credits;