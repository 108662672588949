import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Mail, Clock, Check, ArrowRight, Palette } from 'lucide-react';

const WaitingScreen = () => {
  return (
    <div className="bg-gradient-to-br from-gray-900 to-gray-800 text-white min-h-screen flex flex-col">
      <div className="container mx-auto px-4 mt-8 mb-8">
        <Link to="/" className="flex items-center">
          <motion.img 
            src="/logo.png" 
            alt="PaletteHunt Logo" 
            className="h-16 w-auto"
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5 }}
          />
        </Link>
      </div>
      
      <main className="flex-grow container mx-auto px-4 py-16 text-center">
        <motion.div
          initial={{ opacity: 0, scale: 0.9 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5 }}
          className="mb-8"
        >
          <Check className="w-24 h-24 text-green-500 mx-auto" />
        </motion.div>
        
        <motion.h1 
          className="text-5xl font-bold mb-8 bg-gradient-to-r from-primary to-secondary text-transparent bg-clip-text"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          Your Color Journey Has Begun!
        </motion.h1>
        
        <motion.div 
          className="max-w-3xl mx-auto bg-gray-800 rounded-xl shadow-2xl p-8 mb-12"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.4 }}
        >
          <p className="text-xl mb-6">
            Our AI is hard at work analyzing your images and creating your personalized color palette. This process typically takes about an hour.
          </p>
          
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-8">
            <InfoCard 
              icon={<Mail className="w-12 h-12 text-primary" />}
              description="We'll send you an email once your results are ready."
            />
            <InfoCard 
              icon={<Clock className="w-12 h-12 text-primary" />}
              description="The analysis usually takes about an hour to complete."
            />
            <InfoCard 
              icon={<Palette className="w-12 h-12 text-primary" />}
              description="Once ready, you'll be able to explore your personalized color palette."
            />
          </div>
          
          <p className="text-lg text-gray-300">
            Feel free to close this page. We'll notify you via email when your results are ready!
          </p>
        </motion.div>
        
        <motion.div
          className="text-gray-400 max-w-2xl mx-auto"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.6 }}
        >
          <p className="mb-4">
            While you wait, why not check out our blog for fashion tips and color theory insights?
          </p>
          <Link 
            to="/blog" 
            className="inline-flex items-center text-primary hover:underline transition-all duration-300 group"
          >
            Explore Our Blog
            <ArrowRight className="ml-2 w-4 h-4 group-hover:translate-x-1 transition-transform duration-300" />
          </Link>
        </motion.div>
      </main>
      
      <motion.footer
        className="py-6 text-center text-gray-500"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.8 }}
      >
        <p>&copy; {new Date().getFullYear()} PaletteHunt. All rights reserved.</p>
      </motion.footer>
    </div>
  );
};

const InfoCard = ({ icon, title, description }) => (
  <motion.div 
    className="bg-gray-700 rounded-lg p-6 flex flex-col items-center"
    whileHover={{ scale: 1.05, boxShadow: "0 10px 20px rgba(0,0,0,0.2)" }}
    transition={{ type: "spring", stiffness: 300 }}
  >
    {icon}
    <h3 className="text-xl font-semibold mt-4 mb-2">{title}</h3>
    <p className="text-gray-300">{description}</p>
  </motion.div>
);

export default WaitingScreen;