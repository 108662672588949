import React, { useEffect, useState, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Footer from './Footer';
import { CheckCircle2 } from 'lucide-react';
import { motion } from 'framer-motion';

const PricingComponent = () => {
  return (
    <div className="w-full py-12">
      <div className="max-w-6xl mx-auto px-4">
        <div className="flex flex-col md:flex-row justify-between items-center">
          <div className="w-full md:w-2/5 mb-8 md:mb-0 md:mr-8 text-center md:text-left">
            <h2 className="text-xl font-bold mb-4 text-white">Unbeatable color analysis:</h2>
            <ul className="space-y-3">
              {['Results in less than 1h', '10x cheaper than a stylist', 'Best image quality and reliable'].map((item, index) => (
                <li key={index} className="flex items-center text-gray-200 justify-center md:justify-start">
                  <CheckCircle2 className="text-green-500 mr-2" size={20} />
                  <span>{item}</span>
                </li>
              ))}
            </ul>
          </div>

          <div className="w-full md:w-1/3 text-center mb-8 md:mb-0">
            <div className="text-xl font-semibold text-secondary mb-2">Color Test</div>
            <div className="text-5xl font-bold text-white mb-2">$15</div>
            <div className="w-20 h-1 bg-secondary mx-auto mb-4"></div>
            <p className="text-gray-200 mb-6">Find your perfect color palette</p>
            <button className="bg-secondary text-gray-900 font-semibold py-2 px-6 rounded-full hover:bg-opacity-90 transition duration-300">
              Get Started
            </button>
          </div>

          <div className="w-full md:w-1/2 md:ml-20 text-center md:text-left">
            <h2 className="text-xl font-bold mb-4 text-white">Every package includes:</h2>
            <ul className="space-y-4">
              {['Your color analysis playbook', 'AI Pictures in colors you look great', 'Access to playground to try new styles'].map((item, index) => (
                <li key={index} className="flex items-center text-gray-200 justify-center md:justify-start">
                  <CheckCircle2 className="text-green-500 mr-2" size={20} />
                  <span>{item}</span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

const ColorNugget = ({ colors, isMobile }) => {
  // isMobile = false;
  return (
    <motion.div
      className={`w-5 ${isMobile ? 'h-40' : 'h-48'} rounded-full overflow-hidden shadow-lg ml-2`}
      initial={{ opacity: 0, x: 20 }}
      animate={{ opacity: 1, x: 0 }}
      transition={{ duration: 0.5 }}
    >
      {colors.map((color, index) => (
        <div key={index} className="h-1/3" style={{ backgroundColor: color }} />
      ))}
    </motion.div>
  );
};

const LandingPage = () => {
  const containerRef = useRef(null);
  const svgRef = useRef(null);
  const getStartedRef = useRef(null);
  const navigate = useNavigate();

  // Define isMobile at the component level
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    // Function to update isMobile state on window resize
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Call handleResize initially to set the correct isMobile value
    handleResize();

    // Clean up event listener on unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Define updateArrows function at the component level
  const updateArrows = () => {
    if (containerRef.current && svgRef.current) {
      const container = containerRef.current;
      const svg = svgRef.current;

      svg.innerHTML = '';

      const drawArrow = (
        startEl,
        endEl,
        startXPercent,
        startYPercent,
        endXPercent,
        endYPercent,
        curveFactor = 0.5,
        color = '#FFA500',
        addText = false,
        textContent = '',
        textOffsetX = 0,
        textOffsetY = 0
      ) => {
        if (!startEl || !endEl) return;

        const startRect = startEl.getBoundingClientRect();
        const endRect = endEl.getBoundingClientRect();
        const containerRect = container.getBoundingClientRect();

        const startX = startRect.left + startRect.width * startXPercent - containerRect.left;
        const startY = startRect.top + startRect.height * startYPercent - containerRect.top;
        const endX = endRect.left + endRect.width * endXPercent - containerRect.left;
        const endY = endRect.top + endRect.height * endYPercent - containerRect.top;

        const midX = (startX + endX) / 2;
        const midY = (startY + endY) / 2;

        // Calculate control point for a more pronounced curve
        const dx = endX - startX;
        const dy = endY - startY;
        const normalX = -dy;
        const normalY = dx;
        const distance = Math.sqrt(dx * dx + dy * dy);

        const controlX = midX + (normalX / distance) * curveFactor * 100;
        const controlY = midY + (normalY / distance) * curveFactor * 100;

        const path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
        path.setAttribute('d', `M${startX},${startY} Q${controlX},${controlY} ${endX},${endY}`);
        path.setAttribute('fill', 'none');
        path.setAttribute('stroke', color);
        path.setAttribute('stroke-width', '2');
        svg.appendChild(path);

        // Improved arrowhead
        const arrowSize = 10;
        const arrowWidth = 6;
        const arrowAngle = Math.atan2(endY - controlY, endX - controlX);
        const arrowhead = document.createElementNS('http://www.w3.org/2000/svg', 'path');
        arrowhead.setAttribute(
          'd',
          `M${endX},${endY} 
              L${endX - arrowSize * Math.cos(arrowAngle - Math.PI / 6)},${endY - arrowSize * Math.sin(arrowAngle - Math.PI / 6)}
              L${endX - (arrowSize - arrowWidth / 2) * Math.cos(arrowAngle)},${endY - (arrowSize - arrowWidth / 2) * Math.sin(arrowAngle)}
              L${endX - arrowSize * Math.cos(arrowAngle + Math.PI / 6)},${endY - arrowSize * Math.sin(arrowAngle + Math.PI / 6)}
              Z`
        );
        arrowhead.setAttribute('fill', color);
        svg.appendChild(arrowhead);

        if (addText) {
          // Add text near the arrow
          const text = document.createElementNS('http://www.w3.org/2000/svg', 'text');
          text.setAttribute('x', endX + textOffsetX);
          text.setAttribute('y', endY + textOffsetY);
          text.setAttribute('fill', color);
          text.setAttribute('font-family', '"Nerko One", cursive');
          text.setAttribute('font-size', '17px');
          text.setAttribute('font-weight', 'normal');
          text.textContent = textContent;
          svg.appendChild(text);
        }
      };

      const lauritaSmall = container.querySelector('[alt="Small 3"]');
      const lauritaLarge = container.querySelector('[alt="Large 2"]');
      const olympiaSmall = container.querySelector('[alt="Olympia Small 3"]');
      const olympiaLarge = container.querySelector('[alt="Large 4"]');
      const olympiaSmall1 = container.querySelector('[alt="Olympia Small 1"]');
      const colorPalette = container.querySelector('[alt="Large 1"]');

      // Draw arrows
      drawArrow(
        lauritaSmall,
        lauritaLarge,
        isMobile ? 1.1 : 1.1,
        isMobile ? 0.7 : 0.7,
        isMobile ? 0.85 : 0.7,
        isMobile ? -0.05 : -0.05,
        isMobile ? -0.25 : -0.25,
        '#FFFFFF',
        false
      );

      drawArrow(
        olympiaSmall,
        olympiaLarge,
        1.15,
        0.35,
        isMobile ? 0.8 : 0.7,
        isMobile ? 1.05 : 1.05,
        isMobile ? 0.5 : 0.25,
        '#FFFFFF',
        false
      );

      // if (!isMobile) {
        // Draw 'Upload some pics' arrow
        const drawUploadArrow = () => {
          if (!olympiaSmall1) return;
        
          const rect = olympiaSmall1.getBoundingClientRect();
          const containerRect = container.getBoundingClientRect();
        
          const startX = rect.left - containerRect.left - (isMobile ? 26 : 60);
          const startY = rect.top - containerRect.top + rect.height / 2;
          const endX = rect.left - containerRect.left - (isMobile ? 2 : 5);
          const endY = rect.top - containerRect.top + rect.height / 2;
        
          // Invert the curve by changing the sign of the Y offset
          const controlX = startX - (isMobile ? 10 : 20);
          const controlY = startY + (isMobile ? 20 : 40);
        
          const path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
          path.setAttribute('d', `M${isMobile ? startX + 2 : startX},${isMobile ? startY - 18 : startY} Q${controlX},${controlY} ${endX},${endY}`);
          path.setAttribute('fill', 'none');
          path.setAttribute('stroke', '#FFA500');
          path.setAttribute('stroke-width', '2');
          svg.appendChild(path);
        
          // Arrowhead
          const arrowSize = isMobile ? 8 : 10;
          const arrowAngle = Math.atan2(endY - controlY, endX - controlX);
          const arrowhead = document.createElementNS('http://www.w3.org/2000/svg', 'path');
          arrowhead.setAttribute(
            'd',
            `M${endX},${endY} 
                L${endX - arrowSize * Math.cos(arrowAngle - Math.PI / 6)},${endY - arrowSize * Math.sin(arrowAngle - Math.PI / 6)}
                L${endX - arrowSize * Math.cos(arrowAngle + Math.PI / 6)},${endY - arrowSize * Math.sin(arrowAngle + Math.PI / 6)}
                Z`
          );
          arrowhead.setAttribute('fill', '#FFA500');
          svg.appendChild(arrowhead);
        
          // Text
          const text = document.createElementNS('http://www.w3.org/2000/svg', 'text');
          text.setAttribute('x', startX - (isMobile ? 55 : 85));
          text.setAttribute('y', startY - (isMobile ? 44 : 48)); // Adjusted Y position for mobile
          text.setAttribute('fill', '#FFA500');
          text.setAttribute('font-family', '"Nerko One", cursive');
          text.setAttribute('font-size', isMobile ? '14px' : '17px');
          text.setAttribute('font-weight', 'normal');
          text.setAttribute('transform', `rotate(-20, ${startX + (isMobile ? 45 : 90)}, ${startY - (isMobile ? 55 : 90)})`); // Adjusted rotation point for mobile
          text.textContent = 'Upload some pics';
          svg.appendChild(text);
        };

        // Draw 'AI generated' arrow
        const drawAIGeneratedArrow = () => {
          if (isMobile) return;
          if (!colorPalette) return;

          const rect = colorPalette.getBoundingClientRect();
          const containerRect = container.getBoundingClientRect();

          const startX = rect.left - containerRect.left - (isMobile ? 20 : 40);
          const startY = rect.top - containerRect.top + rect.height * (isMobile ? 0.9 : 0.8);
          const endX = rect.left - containerRect.left - (isMobile ? 2 : 5);
          const endY = rect.top - containerRect.top + rect.height * (isMobile ? 0.9 : 0.8);

          const controlX = startX - (isMobile ? -20 : 10);
          const controlY = startY + (isMobile ? 10 : 20);

          const path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
          path.setAttribute('d', `M${startX},${startY} Q${controlX},${controlY} ${endX},${endY}`);
          path.setAttribute('fill', 'none');
          path.setAttribute('stroke', '#FFA500');
          path.setAttribute('stroke-width', '2');
          svg.appendChild(path);

          // Arrowhead
          const arrowSize = isMobile ? 5 : 10;
          const arrowAngle = Math.atan2(endY - controlY, endX - controlX);
          const arrowhead = document.createElementNS('http://www.w3.org/2000/svg', 'path');
          arrowhead.setAttribute(
            'd',
            `M${endX},${endY} 
              L${endX - arrowSize * Math.cos(arrowAngle - Math.PI / 6)},${endY - arrowSize * Math.sin(arrowAngle - Math.PI / 6)}
              L${endX - arrowSize * Math.cos(arrowAngle + Math.PI / 6)},${endY - arrowSize * Math.sin(arrowAngle + Math.PI / 6)}
              Z`
          );
          arrowhead.setAttribute('fill', '#FFA500');
          svg.appendChild(arrowhead);

          // Text
          const text = document.createElementNS('http://www.w3.org/2000/svg', 'text');
          text.setAttribute('x', startX - (isMobile ? -50 : 55));
          text.setAttribute('y', startY + (isMobile ? 2 : 5));
          text.setAttribute('fill', '#FFA500');
          text.setAttribute('font-family', '"Nerko One", cursive');
          text.setAttribute('font-size', isMobile ? '14px' : '17px');
          text.setAttribute('font-weight', 'normal');
          text.setAttribute('transform', `rotate(-20, ${startX - (isMobile ? 25 : 50)}, ${startY - (isMobile ? 2 : 5)})`);
          if (!isMobile) {
            text.textContent = 'AI';
            text.appendChild(document.createTextNode('\ngenerated'));
          }
          svg.appendChild(text);
        };

        drawUploadArrow();
        drawAIGeneratedArrow();
      }
    // }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      updateArrows();
      window.addEventListener('resize', updateArrows);
    }, 100);

    return () => {
      clearTimeout(timer);
      window.removeEventListener('resize', updateArrows);
    };
  }, [isMobile]);

  const handleImageError = (e) => {
    console.error(`Failed to load image: ${e.target.src}`);
  };

  const ImageWithFallback = ({ src, alt, className, objectPosition }) => {
    const [error, setError] = useState(false);

    const handleError = (e) => {
      console.error(`Failed to load image: ${src}`);
      setError(true);
    };

    return (
      <div className={`relative ${className} transition-transform duration-300 ease-in-out transform hover:scale-110`}>
        {!error ? (
          <img
            src={src}
            alt={alt}
            className="w-full h-full object-cover rounded-lg"
            style={{ objectPosition: objectPosition || 'center' }}
            onError={handleError}
          />
        ) : (
          <div className="absolute inset-0 flex items-center justify-center bg-red-500 text-white text-xs p-2 text-center rounded-lg">
            Image failed to load: {src}
          </div>
        )}
      </div>
    );
  };

  const scrollToGetStarted = () => {
    getStartedRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
  };

  const handleGoogleSuccess = (credentialResponse) => {
    console.log(credentialResponse);
    navigate('/upload');
  };

  const handleGoogleError = () => {
    console.log('Login Failed');
  };

  return (
    <div className="bg-gray-900 text-white flex flex-col min-h-screen">
      <header className="w-full p-4">
        <div className="max-w-7xl mx-auto flex justify-between items-center">
          <div className="flex items-center">
            <img src="/logo.png" alt="PaletteHunt Logo" className="h-12 w-auto mr-2 sm:h-16 sm:mr-3" />
            <span className="hidden sm:block text-xl sm:text-2xl text-white font-bold text-primary">PaletteHunt</span>
          </div>
          <div className="flex-shrink-0 pr-4 sm:pr-32">
            <a
              href="mailto:hi@palettehunt.com"
                className="text-white hover:text-primary transition duration-300 mr-0 sm:mr-16 md:mr-8"
            >
              Contact
          </a>
            <Link
              to="/login"
              className="bg-primary text-white font-semibold py-2 px-4 rounded-full hover:bg-opacity-90 transition duration-300 text-sm sm:text-base"
            >
              Sign In
            </Link>
          </div>
        </div>
      </header>
      <main className="flex-grow">
        <div className="max-w-7xl mx-auto px-4 pt-12 sm:pt-24 pb-12">
          <div className="flex flex-col md:flex-row items-center">
            <div className="md:w-1/2 mb-8 md:mb-0 text-center md:text-left">
              <div
                className="inline-block mb-3 bg-gradient-to-r from-primary to-secondary rounded-full px-4 py-1 sm:px-5 sm:py-2 text-xs sm:text-sm font-bold text-white shadow-lg transform hover:scale-105 transition-transform duration-300"
                style={{ zIndex: 20 }}
              >
                <span className="mr-1 sm:mr-2 text-base sm:text-lg">🎨</span>
                Over 100 images created
              </div>
              <h1 className="text-5xl sm:text-6xl md:text-7xl font-bold mb-4 sm:mb-8 text-center md:text-left">
                Discover
                <br />
                Your Perfect
                <span className="block mt-2">
                  <span className="text-red-400">C</span>
                  <span className="text-yellow-400">o</span>
                  <span className="text-green-400">l</span>
                  <span className="text-blue-400">o</span>
                  <span className="text-indigo-400">r</span>
                  <span className="text-purple-400"> </span>
                  <span className="text-pink-400">P</span>
                  <span className="text-red-400">a</span>
                  <span className="text-yellow-400">l</span>
                  <span className="text-green-400">e</span>
                  <span className="text-blue-400">t</span>
                  <span className="text-indigo-400">t</span>
                  <span className="text-purple-400">e</span>
                </span>
              </h1>
              <p className="text-lg sm:text-xl text-gray-300 mb-8 sm:mb-12 max-w-lg mx-auto md:mx-0">
                Upload your photos and let our AI find the most flattering colors that complement your unique style and complexion
              </p>
              <Link
                to="/login"
                ref={getStartedRef}
                className="bg-primary text-white font-semibold py-2 px-4 sm:py-3 sm:px-6 rounded-full hover:bg-opacity-90 transition duration-300 ease-in-out transform hover:scale-105 text-lg sm:text-xl inline-block mx-auto md:mx-0 mb-12 md:mb-0"
              >
                Get started
              </Link>
            </div>
            <div className="md:w-1/2 flex items-center justify-center px-4 md:px-0">
              <div ref={containerRef} className="relative w-full h-auto md:h-[600px] mb-16 md:mb-0">
                <svg ref={svgRef} className="absolute top-0 left-0 w-full h-full text-secondary pointer-events-none z-10" />
                {/* Images */}
                {/* First row of small images */}
                <div className="flex justify-center mb-2 md:mb-3">
                  <div className="flex space-x-2">
                    <ImageWithFallback src="/laurita.jpg" alt="Small 1" className="w-16 h-16 object-cover rounded-lg" objectPosition="center 30%" />
                    <ImageWithFallback src="/laurita4.jpg" alt="Small 2" className="w-16 h-16 object-cover rounded-lg" />
                    <ImageWithFallback src="/laurita-6.jpg" alt="Small 3" className="w-16 h-16 object-cover rounded-lg" objectPosition="center 30%" />
                  </div>
                </div>
                {/* Large images with arrow */}
                <div className="flex justify-center mt-6 mb-2 md:mb-3">
                  <div className="flex space-x-2 items-start">
                    <ImageWithFallback src="/colorPalette.jpeg" alt="Large 1" className="w-40 h-40 md:w-48 md:h-48 object-cover rounded-lg" />
                    <div className="relative">
                      <ImageWithFallback src="/sunny-yellow.webp" alt="Large 2" className="w-40 h-40 md:w-48 md:h-48 object-cover rounded-lg" />
                      {!isMobile && (
                        <div className="absolute top-0 left-0">
                          {/* Golden arrow and 'AI generated' text */}
                        </div>
                      )}
                    </div>
                    <ColorNugget colors={['#FFD700', '#E2725B', '#8B4513']} isMobile={isMobile} />
                  </div>
                </div>
                {/* Second set of large images */}
                <div className={`flex justify-center mb-2 md:mb-3 ${isMobile ? 'mt-4' : ''}`}>
                  <div className="flex space-x-2 items-start">
                    <ImageWithFallback src="/olympia-ai-2.jpeg" alt="Large 3" className="w-40 h-40 md:w-48 md:h-48 object-cover rounded-lg" />
                    <ImageWithFallback src="/olympia-ai-1.jpeg" alt="Large 4" className="w-40 h-40 md:w-48 md:h-48 object-cover rounded-lg" />
                    <ColorNugget colors={['#80CCFF', '#FFB3B3', '#B266FF']} isMobile={isMobile} />
                  </div>
                </div>
                {/* Second row of small images */}
                  <div className={`flex justify-center ${isMobile ? 'mt-6 md:mt-6' : 'mt-2 md:mt-6'}`}>
                    <div className="flex space-x-2 items-end">
                      <div className="relative">
                      <ImageWithFallback src="/olympia-small-1.jpg" alt="Olympia Small 1" className="w-16 h-16 object-cover rounded-lg" />
                      {!isMobile && (
                        <div className="absolute bottom-0 left-0">
                          {/* Golden arrow and 'Upload some pics' text */}
                        </div>
                      )}
                    </div>
                    <ImageWithFallback src="/olympia-small-2.jpg" alt="Olympia Small 2" className="w-16 h-16 object-cover rounded-lg" objectPosition="center 50%" />
                    <div className="relative">
                      <ImageWithFallback src="/olympia-small-3.jpg" alt="Olympia Small 3" className="w-16 h-16 object-cover rounded-lg" />
                      {!isMobile && (
                        <div className="absolute bottom-0 right-0">
                          {/* White arrow to large image */}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                {/* The updateArrows function is called in useEffect */}
              </div>
            </div>
          </div>
        </div>
        
      {/* New Pricing Component */}
      <div className={`max-w-7xl mx-auto px-4 ${isMobile ? '-mt-10' : ''}`}>
        <PricingComponent />
      </div>

      {/* Add this div to create a clean break between sections */}
      <div className="w-full h-12 bg-gray-900"></div>

      {/* Testimonial Section */}
      <section className="bg-gray-900 py-16">
        <div className="max-w-7xl mx-auto px-4">
          <h2 className="text-4xl font-bold text-center mb-12">What Our Users Say</h2>
          <div className="grid md:grid-cols-2 gap-8">
            <div className="bg-gray-800 rounded-lg p-6 shadow-lg relative">
              <div className="flex text-yellow-400 absolute top-4 right-4">
                {[...Array(5)].map((_, i) => (
                  <svg key={i} className="w-5 h-5 fill-current" viewBox="0 0 24 24">
                    <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z" />
                  </svg>
                ))}
              </div>
              <div className="flex items-center mb-4">
                <img src="/laurita.jpg" alt="Laura" className="w-16 h-16 rounded-full object-cover mr-4" />
                <div>
                  <h3 className="text-xl font-semibold">Laura</h3>
                </div>
              </div>
              <p className="text-gray-300 italic">"PaletteHunt has revolutionized my wardrobe! The AI-generated color palettes are spot-on, and I've never felt more confident in my outfit choices."</p>
            </div>
            <div className="bg-gray-800 rounded-lg p-6 shadow-lg relative">
              <div className="flex text-yellow-400 absolute top-4 right-4">
                {[...Array(5)].map((_, i) => (
                  <svg key={i} className="w-5 h-5 fill-current" viewBox="0 0 24 24">
                    <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z" />
                  </svg>
                ))}
              </div>
              <div className="flex items-center mb-4">
                <img src="/olympia-small-1.jpg" alt="Olympia" className="w-16 h-16 rounded-full object-cover mr-4" />
                <div>
                  <h3 className="text-xl font-semibold">Olympia</h3>
                </div>
              </div>
              <p className="text-gray-300 italic">"As a model, I'm always looking for ways to enhance my look. PaletteHunt has been a game-changer, helping me discover unique color combinations that make me stand out."</p>
            </div>
          </div>
        </div>
      </section>

      {/* How does it work? section */}
      <section className="bg-gray-900 py-12 sm:py-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-3xl sm:text-5xl font-bold text-center text-white mb-8 sm:mb-16">How does it work?</h2>
          <div className="flex flex-col md:flex-row justify-between items-start space-y-12 md:space-y-0 md:space-x-8">
            {[
              { 
                number: 1, 
                text: "Upload between 8 to 10 photos of yourself",
                images: ['/laurita3.jpg', '/laurita4.jpg', '/laurita5.jpg'],
                emoji: "📸"
              },
              { 
                number: 2, 
                text: "Our AI gets to work, and you receive an email after an hour with the results",
                images: ['/ai-training.webp'],
                emoji: "🤖"
              },
              { 
                number: 3, 
                text: "Get your color analysis with beautiful AI pictures of you in those colors",
                images: ['/laurita2.webp'],
                emoji: "🎨"
              }
            ].map((step, index) => (
              <div key={step.number} className="flex flex-col items-center w-full md:w-1/3 relative">
                <div className="bg-white text-gray-900 w-16 h-16 rounded-2xl flex items-center justify-center text-3xl font-bold mb-6">
                  {step.number}
                </div>
                <div className="text-4xl mb-0">{step.emoji}</div>
                <div className="h-72 mb-6 relative w-full">
                  <div className="absolute inset-0 flex justify-center items-center">
                  
                    {step.images.map((img, imgIndex) => (
                      <ImageWithFallback
                        key={imgIndex}
                        src={img} 
                        alt={`Step ${step.number} illustration ${imgIndex + 1}`}
                        className={`rounded-lg shadow-lg absolute 
                          ${step.number === 1 
                            ? `w-48 h-48 ${imgIndex === 0 
                                ? 'left-0 -rotate-3' 
                                : imgIndex === 1 
                                  ? 'left-1/13 rotate-3' 
                                  : 'left-1/14 -rotate-3'}`
                            : 'w-56 h-56'
                          }`}
                        style={{
                          zIndex: step.number === 1 ? 3 - imgIndex : 1,
                          transform: `${step.number === 1 
                            ? imgIndex === 0 
                              ? 'translateX(-50%) rotate(-3deg)' 
                              : imgIndex === 1 
                                ? 'translateX(-25%) rotate(3deg)' 
                                : 'translateX(0%) rotate(-3deg)'
                            : 'translateX(-50%)'
                          }`
                        }}
                        objectPosition={step.number === 1 ? `left-${imgIndex === 0 ? 0 : imgIndex === 1 ? 1/13 : 1/14}` : 'center'}
                      />
                    ))}
                  </div>
                </div>
                <p className="text-center text-xl">{step.text}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Redesigned Comparison Section */}
      <section className="py-20">
        <div className="max-w-7xl mx-auto px-4">
          <h2 className="text-5xl font-bold text-center text-white mb-6">Experience the Future of Color Analysis</h2>
          <p className="text-xl text-center text-gray-300 mb-16 max-w-3xl mx-auto">
            Discover colors that perfectly complement your unique features, including shades you've never tried before. Unlock your full style potential.
          </p>
          <div className="flex flex-col md:flex-row justify-between items-stretch space-y-12 md:space-y-0 md:space-x-12">
            {/* Traditional Method */}
            <div className="w-full md:w-1/2 bg-gray-800 rounded-2xl p-8 shadow-2xl transform transition-all duration-300 hover:scale-105">
              <h3 className="text-3xl font-semibold mb-4 text-white">In-Person Color Analysis</h3>
              <div className="mb-8 h-72 rounded-xl overflow-hidden bg-gray-700">
                <img 
                  src="/traditional-analysis-1.webp" 
                  alt="Traditional Color Analysis" 
                  className="w-full h-full object-cover object-top"
                  style={!isMobile ? { objectPosition: 'center -90px' } : {}}
                />
              </div>
              <ul className="space-y-4">
                {[
                  "Time-consuming in-person appointments",
                  "Expensive consultations",
                  "Limited to local experts",
                  "Subjective analysis"
                ].map((item, index) => (
                  <li key={index} className="flex items-center">
                    <svg className="w-6 h-6 text-red-500 mr-2 mt-1 flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path></svg>
                    <span className="text-gray-300">{item}</span>
                  </li>
                ))}
              </ul>
            </div>

            {/* AI-Powered Method */}
            <div className="w-full md:w-1/2 bg-gray-800 rounded-2xl p-8 shadow-2xl transform transition-all duration-300 hover:scale-105">
              <h3 className="text-2xl font-semibold mb-4 bg-clip-text text-transparent bg-gradient-to-r from-primary to-secondary">AI-Powered Method</h3>
              <div className="mb-8 h-72 rounded-xl overflow-hidden flex justify-center items-center space-x-4">
                <img 
                  src="/ai-analysis-1.webp" 
                  alt="AI-Powered Color Analysis 1" 
                  className="w-1/3 h-full object-cover rounded-xl shadow-lg"
                  style={{ objectPosition: '' }}
                />
                <img 
                  src="/ai-analysis-2.webp" 
                  alt="AI-Powered Color Analysis 2" 
                  className="w-1/3 h-full object-cover rounded-xl shadow-lg"
                  style={{ objectPosition: 'center 0px' }}
                />
                <img 
                  src="/ai-analysis-3.webp" 
                  alt="AI-Powered Color Analysis 3" 
                  className="w-1/3 h-full object-cover rounded-xl shadow-lg"
                  style={{ objectPosition: 'center' }}
                />
              </div>
              <ul className="space-y-4">
                {[
                  "Quick results in less than an hour",
                  "Affordable and accessible from anywhere",
                  "Advanced AI trained on thousands of samples",
                  "Objective analysis with personalized results"
                ].map((item, index) => (
                  <li key={index} className="flex items-center">
                    <svg className="w-6 h-6 text-green-500 mr-2 mt-1 flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path></svg>
                    <span className={`text-gray-300 ${index === 4 ? 'font-semibold text-green-400' : ''}`}>{item}</span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="text-center mt-12 sm:mt-16">
            <p className="text-lg sm:text-xl text-gray-300 mb-6">
              Unlock your true color potential and transform your style with AI-powered precision.
            </p>
            <Link
              to="/login"
              className="bg-primary text-white font-semibold py-4 px-10 rounded-full hover:bg-opacity-90 transition duration-300 ease-in-out transform hover:scale-105 text-xl inline-block shadow-lg"
            >
              Experience AI Color Analysis Now
            </Link>
          </div>
        </div>
      </section>

      {/* Pricing Section */}
      <section className="bg-gray-900 py-16">
        <div className="max-w-7xl mx-auto px-4">
          <h2 className="text-4xl font-bold text-center mb-12">Choose Your Plan</h2>
          <div className="flex flex-col md:flex-row justify-center items-stretch gap-8">
            {/* Basic Plan */}
            <div 
              className="bg-gray-800 rounded-lg p-8 w-full md:w-80 flex flex-col transition-all duration-300 ease-in-out transform hover:scale-105 cursor-pointer"
              onClick={scrollToGetStarted}
            >
              <h3 className="text-2xl font-semibold mb-4">Basic Plan</h3>
              <p className="text-4xl font-bold mb-6">$15<span className="text-xl font-normal"></span></p>
              <ul className="mb-8 flex-grow">
                <li className="flex items-center mb-2">
                  <svg className="w-5 h-5 mr-2 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path></svg>
                  Playbook with 6 images
                </li>
                <li className="flex items-center mb-2">
                  <svg className="w-5 h-5 mr-2 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path></svg>
                  Color analysis test
                </li>
                <li className="flex items-center">
                  <svg className="w-5 h-5 mr-2 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path></svg>
                  20 playground credits
                </li>
              </ul>
              <button className="bg-primary text-white font-semibold py-2 px-4 rounded-full hover:bg-opacity-90 transition duration-300 mt-auto">
                Choose Basic
              </button>
            </div>

            {/* Pro Plan (Highlighted) */}
            <div 
              className="bg-gradient-to-br from-primary to-secondary rounded-lg p-8 w-full md:w-80 flex flex-col transform md:scale-110 shadow-xl transition-all duration-300 ease-in-out hover:scale-115 cursor-pointer z-10"
              onClick={scrollToGetStarted}
            >
              <h3 className="text-2xl font-semibold mb-4">Pro Plan</h3>
              <p className="text-4xl font-bold mb-6">$25<span className="text-xl font-normal"></span></p>
              <ul className="mb-8 flex-grow">
                <li className="flex items-center mb-2">
                  <svg className="w-5 h-5 mr-2 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path></svg>
                  Playbook with 10 images
                </li>
                <li className="flex items-center mb-2">
                  <svg className="w-5 h-5 mr-2 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path></svg>
                  Color analysis test
                </li>
                <li className="flex items-center mb-2">
                  <svg className="w-5 h-5 mr-2 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path></svg>
                  50 playground credits
                </li>
                <li className="flex items-center">
                  <svg className="w-5 h-5 mr-2 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path></svg>
                  Gold or Silver test
                </li>
              </ul>
              <button className="bg-white text-primary font-semibold py-2 px-4 rounded-full hover:bg-gray-100 transition duration-300 mt-auto">
                Choose Pro
              </button>
            </div>

            {/* New Enterprise Plan */}
            <div 
              className="bg-gray-800 rounded-lg p-8 w-full sm:w-80 flex flex-col transition-all duration-300 ease-in-out transform hover:scale-105 cursor-pointer"
              onClick={scrollToGetStarted}
            >
              <h3 className="text-2xl font-semibold mb-4">Enterprise Plan</h3>
              <p className="text-4xl font-bold mb-6">$42<span className="text-xl font-normal"></span></p>
              <ul className="mb-8 flex-grow">
                <li className="flex items-center mb-2">
                  <svg className="w-5 h-5 mr-2 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path></svg>
                  Playbook with 20 images
                </li>
                <li className="flex items-center mb-2">
                  <svg className="w-5 h-5 mr-2 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path></svg>
                  Advanced color analysis
                </li>
                <li className="flex items-center mb-2">
                  <svg className="w-5 h-5 mr-2 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path></svg>
                  100 playground credits
                </li>
                <li className="flex items-center mb-2">
                  <svg className="w-5 h-5 mr-2 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path></svg>
                  24/7 priority support
                </li>
                <li className="flex items-center">
                  <svg className="w-5 h-5 mr-2 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path></svg>
                  Seasonal color updates
                </li>
              </ul>
              <button className="bg-primary text-white font-semibold py-2 px-4 rounded-full hover:bg-opacity-90 transition duration-300 mt-auto">
                Choose Enterprise
              </button>
            </div>
          </div>
        </div>
      </section>

      {/* FAQ Section */}
<section className="bg-gray-900 py-12 sm:py-16">
  <div className="max-w-7xl mx-auto px-4">
    <h2 className="text-3xl sm:text-4xl font-bold text-center mb-8 sm:mb-12">Frequently Asked Questions</h2>
    <div className="grid md:grid-cols-2 gap-6">
      <div className="text-center md:text-left">
        <h3 className="text-xl font-semibold mb-2">How does PaletteHunt work?</h3>
        <p className="text-gray-300 mb-6 mr-0 md:mr-8">
          PaletteHunt uses advanced AI algorithms to analyze your photos and generate personalized color palettes that complement your unique features and style preferences.
        </p>

        <h3 className="text-xl font-semibold mb-2">How many photos should I upload?</h3>
        <p className="text-gray-300 mb-6 mr-0 md:mr-8">
          For optimal results, we recommend uploading 8-10 photos that showcase different angles, lighting conditions, and outfits.
        </p>

        <h3 className="text-xl font-semibold mb-2">Is my data secure?</h3>
        <p className="text-gray-300 mb-6 mr-0 md:mr-8">
          Yes, we take data security very seriously. All uploaded photos are encrypted and securely stored. We never share your personal information or photos with third parties.
        </p>
      </div>
      <div className="text-center md:text-left">
        <h3 className="text-xl font-semibold mb-2">Can I use PaletteHunt on mobile devices?</h3>
        <p className="text-gray-300 mb-6 mr-0 md:mr-8">
          Yes! PaletteHunt is fully responsive and works seamlessly on both desktop and mobile devices, allowing you to access your personalized color palettes on the go.
        </p>

        <h3 className="text-xl font-semibold mb-2">How accurate are the color recommendations?</h3>
        <p className="text-gray-300 mb-6 mr-0 md:mr-8">
          Our AI model is trained on a vast dataset of fashion and color theory, providing highly accurate recommendations. However, personal preference always plays a role in fashion choices.
        </p>

        <h3 className="text-xl font-semibold mb-2">Can I get a refund if I'm not satisfied?</h3>
        <p className="text-gray-300 mb-6 mr-0 md:mr-8">
          We offer a 30-day money-back guarantee. If you're not completely satisfied with our service, you can request a full refund within 30 days of your purchase.
        </p>
      </div>
    </div>
  </div>
</section>


      <Footer />
    </main>
  </div>
);
};

export default LandingPage;