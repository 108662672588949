import React from 'react';
import { Link } from 'react-router-dom';

const TermsAndConditions = () => {
  return (
    <div className="bg-gray-900 text-white min-h-screen">
      <div className="max-w-4xl mx-auto px-4 py-16">
        {/* Logo and Title */}
        <div className="flex items-center mb-8">
          <img src="/logo.png" alt="PaletteHunt Logo" className="h-10 w-auto mr-3" />
          <span className="text-2xl text-white font-bold">PaletteHunt</span>
        </div>

        <h1 className="text-4xl font-bold mb-8">Terms & Conditions</h1>
        
        <p className="mb-6">Welcome to PaletteHunt. By using our service, you agree to these terms. Please read them carefully.</p>
        
        <h2 className="text-2xl font-semibold mt-8 mb-4">1. Service Description</h2>
        <p className="mb-6">PaletteHunt provides AI-powered color analysis services. We use advanced algorithms to analyze your photos and generate personalized color palettes.</p>
        
        <h2 className="text-2xl font-semibold mt-8 mb-4">2. User Responsibilities</h2>
        <p className="mb-6">You are responsible for maintaining the confidentiality of your account and for all activities that occur under your account. You must not share your account credentials with others.</p>
        
        <h2 className="text-2xl font-semibold mt-8 mb-4">3. Content Ownership</h2>
        <p className="mb-6">You retain all rights to the photos you upload. By using our service, you grant us a limited license to use your photos for the purpose of color analysis. We delete all user photos after one week.</p>
        
        <h2 className="text-2xl font-semibold mt-8 mb-4">4. Prohibited Activities</h2>
        <p className="mb-6">You agree not to misuse our services or help anyone else do so. For example, you must not attempt to access our services using a method other than the interface and instructions we provide.</p>
        
        <h2 className="text-2xl font-semibold mt-8 mb-4">5. Termination</h2>
        <p className="mb-6">We reserve the right to suspend or terminate your access to our services at any time, without notice, for conduct that we believe violates these Terms or is harmful to other users of our services, us, or third parties, or for any other reason.</p>
        
        <h2 className="text-2xl font-semibold mt-8 mb-4">6. Changes to Terms</h2>
        <p className="mb-6">We may modify these terms or any additional terms that apply to a Service to, for example, reflect changes to the law or changes to our Services. You should look at the terms regularly.</p>
        
        <p className="mt-12">By using PaletteHunt, you agree to these terms. If you do not agree to these terms, please do not use our service.</p>
        
        <div className="mt-12">
          <Link to="/" className="text-primary hover:text-secondary">Return to Home</Link>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditions;