import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { FaDownload, FaCamera, FaArrowLeft, FaPalette, FaCoins } from 'react-icons/fa';
import { MdPhotoLibrary } from 'react-icons/md';
import { ClipLoader } from 'react-spinners';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Playground = () => {
  const [uuid, setUuid] = useState(null);
  const [background, setBackground] = useState('');
  const [hairColor, setHairColor] = useState('');
  const [hairstyle, setHairstyle] = useState('');
  const [outfit, setOutfit] = useState('');
  const [makeup, setMakeup] = useState('');
  const [makeupColor, setMakeupColor] = useState('');
  const [shades, setShades] = useState('');
  const [generatedImage, setGeneratedImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [progress, setProgress] = useState(0);
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('playground');
  const [cameraRollImages, setCameraRollImages] = useState([]);
  const [cameraRollLoading, setCameraRollLoading] = useState(false);
  const [displayedImages, setDisplayedImages] = useState([]);
  const [currentImageCount, setCurrentImageCount] = useState(12);
  const [credits, setCredits] = useState(null);
  const location = useLocation();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [isGenerating, setIsGenerating] = useState(false);

  // Options for dropdowns
  const backgroundOptions = ['Beach', 'City', 'Forest', 'Mountains', 'Studio', 'Park', 'Cafe', 'Office', 'Home', 'Garden', 'Library', 'Art Gallery', 'Rooftop', 'Countryside', 'Subway'];
  const hairColorOptions = ['Blonde', 'Brown', 'Black', 'Red', 'Gray', 'Auburn', 'Platinum', 'Ombre', 'Highlights', 'Pastel', 'Ginger', 'Ash', 'Honey', 'Caramel', 'Jet Black'];
  const hairstyleOptions = ['Straight', 'Wavy', 'Curly', 'Bob', 'Pixie', 'Long', 'Braided', 'Updo', 'Ponytail', 'Bun', 'Layered', 'Bangs', 'Afro', 'Mohawk', 'Shaved'];
  const outfitOptions = ['Casual', 'Formal', 'Sporty', 'Bohemian', 'Vintage', 'Business', 'Streetwear', 'Elegant', 'Beachwear', 'Punk', 'Preppy', 'Goth', 'Minimalist', 'Retro', 'Athleisure'];
  const makeupOptions = ['Natural', 'Glamorous', 'Smokey', 'No Makeup', 'Bold Lip', 'Colorful', 'Minimal', 'Gothic', 'Dewy', 'Matte', 'Cat Eye', 'Glitter', 'Bronzed', 'Pastel', 'Avant-garde'];
  const makeupColorOptions = ['Nude', 'Red', 'Pink', 'Purple', 'Blue', 'Green', 'Gold', 'Silver', 'Bronze', 'Coral', 'Berry', 'Peach', 'Plum', 'Burgundy', 'Turquoise'];
  const shadesOptions = [
    'Light Spring', 'Clear Spring', 'Warm Spring', 
    'Light Summer', 'Cool Summer', 'Soft Summer', 
    'Warm Autumn', 'Soft Autumn', 'Dark Autumn', 
    'Dark Winter', 'Clear Winter', 'Cool Winter',
    'Bright Spring', 'True Spring', 'True Summer',
    'True Autumn', 'Deep Autumn', 'Bright Winter'
  ];

  useEffect(() => {
    const verifyToken = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          navigate('/login');
          return;
        }

        const response = await axios.post(
          `${process.env.REACT_APP_API_GATEWAY_ENDPOINT}/verify-token`,
          { token },
          {
            headers: {
              'Content-Type': 'application/json'
            }
          }
        );

        if (response.data.valid) {
          setUuid(response.data.uuid);
          fetchCredits(response.data.uuid);  // Fetch credits after setting UUID
        } else {
          localStorage.removeItem('token');
          navigate('/login');
        }
      } catch (error) {
        console.error('Error verifying token:', error);
        navigate('/login');
      }
    };

    verifyToken();
  }, [navigate]);

  const handleApply = async () => {
    if (credits < 10) {
      setError("Not enough credits. Please purchase more credits to generate images.");
      return;
    }

    setActiveTab('playground');
    setIsLoading(true);
    setIsGenerating(true);
    setError(null);
    setProgress(0);
    const imageId = Math.floor(Math.random() * 1000000);

    // Start the progress animation
    startProgressAnimation();

    // Trigger image generation
    try {
      await axios.post(
        `${process.env.REACT_APP_API_GATEWAY_ENDPOINT}/generate-images`,
        {
          purpose: 'playground',
          uuid,
          background,
          hairColor,
          hairstyle,
          outfit,
          makeup,
          makeupColor,
          shades,
          image_id: imageId
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        }
      );

      // Start polling immediately after successful generation request
      pollForImage(imageId);
    } catch (error) {
      console.error('Error triggering image generation:', error);
      setIsLoading(false);
      setIsGenerating(false);
      setError('Failed to generate image. Please simply try again.');
    }
  };

  const startProgressAnimation = () => {
    let startTime = Date.now();
    const initialDuration = 30000; // 30 seconds to reach 90%
    const finalDuration = 60000; // Additional 30 seconds to reach 99%

    const animateProgress = () => {
      const currentTime = Date.now();
      const elapsed = currentTime - startTime;

      let progress;
      if (elapsed < initialDuration) {
        progress = Math.min((elapsed / initialDuration) * 90, 90);
      } else {
        const remainingTime = elapsed - initialDuration;
        progress = 90 + Math.min((remainingTime / finalDuration) * 9, 9);
      }

      setProgress(progress);

      if (progress < 99) {
        requestAnimationFrame(animateProgress);
      }
    };

    requestAnimationFrame(animateProgress);
  };

  const pollForImage = (imageId) => {
    let pollCount = 0;
    const maxPolls = 90; // 3 minutes at 2-second intervals
    
    const pollInterval = setInterval(() => {
      pollCount++;
      
      if (pollCount > maxPolls) {
        clearInterval(pollInterval);
        setIsLoading(false);
        setIsGenerating(false);
        setError('Image generation timed out. Please try again.');
        return;
      }

      axios.get(
        `${process.env.REACT_APP_API_GATEWAY_ENDPOINT}/get-images`,
        {
          params: { image_id: imageId, purpose: 'playground', uuid },
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        }
      ).then(response => {
        console.log('Response:', response.data);
        if (response.data.image_url) {
          setGeneratedImage(response.data.image_url);
          setIsLoading(false);
          setIsGenerating(false);
          setProgress(100);
          clearInterval(pollInterval);
          // Deduct credits locally only after successful generation
          setCredits(prevCredits => Math.max(prevCredits - 10, 0));
        }
      }).catch(error => {
        console.error('Error polling for image:', error);
      });
    }, 2000);
  };

  const fetchCameraRoll = async () => {
    setCameraRollLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_GATEWAY_ENDPOINT}/get-images`,
        {
          params: { purpose: 'camera_roll', uuid },
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        }
      );
      if (response.data.camera_roll && Array.isArray(response.data.camera_roll)) {
        setCameraRollImages(response.data.camera_roll);
      } else {
        setCameraRollImages([]);
      }
    } catch (error) {
      console.error('Error fetching camera roll:', error);
      setCameraRollImages([]);
    } finally {
      setCameraRollLoading(false);
    }
  };

  const fetchCredits = async (uuid) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_GATEWAY_ENDPOINT}/get-images`,
        {
          params: { purpose: 'credits', uuid },
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        }
      );
      if (response.data.credits !== undefined) {
        setCredits(response.data.credits);
      }
    } catch (error) {
      console.error('Error fetching credits:', error);
    }
  };

  useEffect(() => {
    if (activeTab === 'camera_roll' && uuid) {
      fetchCameraRoll();
    }
  }, [activeTab, uuid]);

  const handleDownload = (imageUrl) => {
    const link = document.createElement('a');
    link.href = imageUrl;
    link.download = 'palettehunt_image.jpg';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const loadMoreImages = () => {
    setCurrentImageCount(prevCount => prevCount + 12);
  };

  useEffect(() => {
    setDisplayedImages(cameraRollImages.slice().reverse().slice(0, currentImageCount));
  }, [cameraRollImages, currentImageCount]);

  const handleBuyCredits = () => {
    navigate('/credits');
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const sessionId = queryParams.get('session_id');

    if (sessionId) {
      // Clear the session_id from the URL
      navigate('/playground', { replace: true });

      // Show success message
      toast.success('Credits purchased successfully!', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      // Refresh credits
      fetchCredits(uuid);
    }
  }, [location, navigate, uuid]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Call it initially

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="bg-gray-900 text-white min-h-screen p-4 sm:p-8">
      <div className="max-w-7xl mx-auto">
        <header className="flex justify-between items-center mb-6 sm:mb-8">
          <Link to="/" className="flex items-center">
            <img src="/logo.png" alt="PaletteHunt Logo" className="h-10 w-auto sm:h-12" />
            {!isMobile && <span className="text-2xl sm:text-3xl font-bold text-white ml-2">PaletteHunt</span>}
          </Link>
          <Link to="/color-analysis" className="bg-primary text-white font-semibold py-2 px-3 sm:px-4 rounded-full hover:bg-opacity-90 transition duration-300 ease-in-out transform hover:scale-105 shadow-lg flex items-center">
            <FaArrowLeft className="mr-1 sm:mr-2" /> 
            <span className="text-sm sm:text-base">Back to Analysis</span>
          </Link>
        </header>

        <div className="flex flex-col lg:flex-row space-y-6 sm:space-y-8 lg:space-y-0 lg:space-x-8">
          {/* Left column - Parameters */}
          <div className="lg:w-1/3 space-y-4 sm:space-y-6">
            {[
              { label: 'Background', value: background, setter: setBackground, options: backgroundOptions },
              { label: 'Hair Color', value: hairColor, setter: setHairColor, options: hairColorOptions },
              { label: 'Hairstyle', value: hairstyle, setter: setHairstyle, options: hairstyleOptions },
              { label: 'Outfit', value: outfit, setter: setOutfit, options: outfitOptions },
              { label: 'Makeup', value: makeup, setter: setMakeup, options: makeupOptions },
              { label: 'Makeup Color', value: makeupColor, setter: setMakeupColor, options: makeupColorOptions },
              { label: 'Color Palette', value: shades, setter: setShades, options: shadesOptions },
            ].map(({ label, value, setter, options }) => (
              <div key={label}>
                <label className="block mb-2 font-semibold">{label}:</label>
                <select
                  value={value}
                  onChange={(e) => setter(e.target.value)}
                  className="w-full p-2 border rounded bg-gray-800 text-white"
                >
                  <option value="">Select {label.toLowerCase()}</option>
                  {options.map((option) => (
                    <option key={option} value={option}>{option}</option>
                  ))}
                </select>
              </div>
            ))}
            <button
              onClick={handleApply}
              disabled={isGenerating || credits < 10}
              className={`w-full bg-gradient-to-r from-primary to-secondary text-white font-semibold py-3 px-6 rounded-full transition duration-300 ease-in-out transform hover:scale-105 shadow-lg ${
                isGenerating ? 'opacity-50 cursor-not-allowed' : credits < 10 ? 'opacity-50 cursor-not-allowed' : 'hover:opacity-90'
              }`}
            >
              {isGenerating ? (
                <div className="flex items-center justify-center">
                  <ClipLoader color="#ffffff" loading={true} size={20} />
                  <span className="ml-2">Generating...</span>
                </div>
              ) : credits < 10 ? (
                'Not Enough Credits'
              ) : (
                'Generate ✨'
              )}
            </button>
          </div>

          {/* Right column - Image and Camera Roll */}
          <div className="lg:w-2/3">
            {/* Tabs and Credits */}
            <div className="flex justify-between items-center mb-6">
              <div className="flex">
                <button
                  className={`flex items-center px-3 sm:px-4 py-2 rounded-tl-lg rounded-tr-lg text-sm sm:text-base ${
                    activeTab === 'playground' ? 'bg-gradient-to-r from-primary to-secondary text-white' : 'bg-gray-700 text-gray-300'
                  }`}
                  onClick={() => setActiveTab('playground')}
                >
                  <FaCamera className="mr-1 sm:mr-2" /> Playground
                </button>
                <button
                  className={`flex items-center px-3 sm:px-4 py-2 rounded-tl-lg rounded-tr-lg ml-2 text-sm sm:text-base ${
                    activeTab === 'camera_roll' ? 'bg-gradient-to-r from-primary to-secondary text-white' : 'bg-gray-700 text-gray-300'
                  }`}
                  onClick={() => setActiveTab('camera_roll')}
                >
                  <MdPhotoLibrary className="mr-1 sm:mr-2" /> Camera Roll
                </button>
              </div>
              <div className="flex items-center">
                <div className={`font-bold flex items-center ${credits === 0 ? 'text-red-500' : 'text-yellow-400'}`}>
                  <FaCoins className="mr-1 sm:mr-2" />
                  <span>{credits !== null ? credits : 0}</span>
                </div>
                {credits === 0 && (
                  <button
                    onClick={handleBuyCredits}
                    className="ml-2 bg-primary text-white font-semibold py-1 px-2 rounded-full hover:bg-opacity-90 transition duration-300 ease-in-out transform hover:scale-105 shadow-lg text-xs"
                  >
                    Buy More
                  </button>
                )}
              </div>
            </div>

            {activeTab === 'playground' && (
              <div className="relative">
                <div className="bg-gradient-to-br from-primary to-secondary p-1 rounded-lg shadow-lg">
                  <div className="bg-gray-800 rounded-lg overflow-hidden mb-4 relative w-full" style={{ height: isMobile ? '400px' : '580px' }}>
                    {credits < 10 && !isGenerating && !generatedImage ? (
                      <div className="flex flex-col items-center justify-center h-full">
                        <p className="text-gray-400 text-lg mb-4">You've run out of credits!</p>
                        <button 
                          onClick={handleBuyCredits}
                          className="bg-yellow-400 text-gray-900 font-semibold py-2 px-4 rounded-full hover:bg-opacity-90 transition duration-300 ease-in-out transform hover:scale-105 shadow-lg"
                        >
                          Buy More Credits
                        </button>
                      </div>
                    ) : isLoading ? (
                      <div className="absolute inset-0 flex flex-col items-center justify-center bg-gray-900 bg-opacity-75">
                        <div className="w-32 h-32 relative">
                          <svg className="w-full h-full" viewBox="0 0 100 100">
                            <circle
                              className="text-gray-300"
                              strokeWidth="4"
                              stroke="currentColor"
                              fill="transparent"
                              r="48"
                              cx="50"
                              cy="50"
                            />
                            <circle
                              className="text-primary"
                              strokeWidth="4"
                              strokeDasharray={300}
                              strokeDashoffset={300 - (progress / 100) * 300}
                              strokeLinecap="round"
                              stroke="currentColor"
                              fill="transparent"
                              r="48"
                              cx="50"
                              cy="50"
                            />
                          </svg>
                          <div className="absolute inset-0 flex items-center justify-center">
                            <span className="text-xl font-semibold">{Math.round(progress)}%</span>
                          </div>
                        </div>
                        <p className="mt-4 text-lg font-semibold text-white">Generating your image...</p>
                        <p className="mt-2 text-sm text-gray-300">This might take a minute, please don't reload the page.</p>
                      </div>
                    ) : generatedImage ? (
                      <img src={generatedImage} alt="Generated" className="w-full h-full object-contain" />
                    ) : (
                      <div className="flex items-center justify-center h-full">
                        <p className="text-gray-400 text-lg">Your generated image will appear here</p>
                      </div>
                    )}
                  </div>
                </div>
                {generatedImage && activeTab === 'playground' && (
                  <button
                    onClick={() => handleDownload(generatedImage)}
                    className="absolute top-2 right-2 bg-white text-gray-800 font-semibold py-2 px-4 rounded-full hover:bg-opacity-90 transition duration-300 ease-in-out transform hover:scale-105 shadow-lg flex items-center"
                  >
                    <FaDownload className="mr-2" /> Download
                  </button>
                )}
              </div>
            )}

            {activeTab === 'camera_roll' && (
              <div className="mt-6">
                {cameraRollLoading ? (
                  <div className="flex justify-center items-center h-64">
                    <ClipLoader color="#ffffff" loading={true} size={50} />
                  </div>
                ) : displayedImages.length > 0 ? (
                  <>
                    <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4">
                      {displayedImages.map((imageUrl, index) => (
                        <div key={index} className="relative group">
                          <img src={imageUrl} alt={`Generated ${cameraRollImages.length - index}`} className="w-full h-40 sm:h-48 object-cover rounded-lg" />
                          <div className="absolute inset-0 bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 transition-opacity duration-300 flex items-center justify-center">
                            <button
                              onClick={() => handleDownload(imageUrl)}
                              className="bg-primary text-white font-semibold py-2 px-4 rounded-full hover:bg-opacity-90 transition duration-300 ease-in-out transform hover:scale-105 shadow-lg flex items-center text-sm"
                            >
                              <FaDownload className="mr-2" /> Download
                            </button>
                          </div>
                        </div>
                      ))}
                    </div>
                    {cameraRollImages.length > currentImageCount && (
                      <div className="mt-6 text-center">
                        <button
                          onClick={loadMoreImages}
                          className="bg-primary text-white font-semibold py-2 px-4 rounded-full hover:bg-opacity-90 transition duration-300 ease-in-out transform hover:scale-105 shadow-lg"
                        >
                          Load More
                        </button>
                      </div>
                    )}
                  </>
                ) : (
                  <div className="text-center text-gray-400 p-8 bg-gray-800 rounded-lg">
                    <p className="text-lg mb-2">Your camera roll is empty.</p>
                    <p>Generate some images in the Playground to see them here!</p>
                  </div>
                )}
              </div>
            )}

            {error && <p className="text-red-500 mt-4">{error}</p>}
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Playground;