import React from 'react';
import { Link } from 'react-router-dom';

const PrivacyPolicy = () => {
  return (
    <div className="bg-gray-900 text-white min-h-screen">
      <div className="max-w-4xl mx-auto px-4 py-16">
        {/* Logo and Title */}
        <div className="flex items-center mb-8">
          <img src="/logo.png" alt="PaletteHunt Logo" className="h-10 w-auto mr-3" />
          <span className="text-2xl text-white font-bold">PaletteHunt</span>
        </div>

        <h1 className="text-4xl font-bold mb-8">Privacy Policy</h1>
        
        <p className="mb-6">At PaletteHunt, we prioritize your privacy and the protection of your data. All information collected, both personal and non-personal, is safeguarded with the highest standards.</p>
        
        <p className="mb-6">PaletteHunt is committed to maintaining the trust and confidence of our users. We want you to understand when and why we collect your personal information, how we use it, the conditions under which we may disclose it to others, and how we keep it secure.</p>
        
        <h2 className="text-2xl font-semibold mt-8 mb-4">Key Points:</h2>
        <ul className="list-disc list-inside mb-6">
          <li>We delete all user photos after one week</li>
          <li>We only keep your email address for identification purposes</li>
          <li>We do not share your personal information with third parties</li>
        </ul>
        
        <h2 className="text-2xl font-semibold mt-8 mb-4">Information Collection and Use</h2>
        <p className="mb-6">We collect your email address for account identification and communication purposes. The photos you upload are used solely for color analysis and are automatically deleted after one week.</p>
        
        <h2 className="text-2xl font-semibold mt-8 mb-4">Data Security</h2>
        <p className="mb-6">We implement robust security measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction.</p>
        
        <h2 className="text-2xl font-semibold mt-8 mb-4">Your Rights</h2>
        <p className="mb-6">You have the right to access, correct, or delete your personal information at any time. Please contact us if you wish to exercise these rights.</p>
        
        <p className="mt-12">For any questions or concerns regarding our privacy practices, please contact us at support@palettehunt.com</p>
        
        <div className="mt-12">
          <Link to="/" className="text-primary hover:text-secondary">Return to Home</Link>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;